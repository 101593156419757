/* 
   * Este arquivo CSS define estilos para uma página da web. 
   * Ele importa a fonte 'Junge' do Google Fonts para uso em toda a página. 
*/

/* 
   * Define estilos padrão para todos os elementos, incluindo a fonte 'Junge', 
   * e garante que a caixa de modelagem seja de borda-caixa, com margens e 
   * preenchimentos definidos como 0. 
*/
@import url("https://fonts.googleapis.com/css2?family=Junge&display=swap");
* {
  font-family: "Junge", serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: transparent;
  outline: none;
}

/* 
   * Estiliza o elemento com o ID 'root', que provavelmente é o contêiner principal da página. 
   * Define um fundo usando uma combinação de gradientes lineares para criar um efeito visual. 
   * Configura um preenchimento interno de 5 pixels e especifica largura e altura mínimas para 
   * garantir que o conteúdo da página seja totalmente visível. 
   * Também desativa a seleção de texto do usuário e configura o layout flexível em coluna. 
*/
#root {
  background: linear-gradient(
    180deg,
    rgba(173, 216, 230, 0.9) 36.46%, /* Azul bebê */
    rgba(173, 216, 230, 0.69) 72.4%, /* Azul bebê (com menor opacidade) */
    rgba(173, 216, 230, 0.97) 100%   /* Azul bebê */
  );

  padding: 5px;
  min-width: 100vw;
  min-height: 100vh;
  user-select: none;
  display: flex;
  flex-direction: column;
}

/* 
   * Define estilos adicionais para o elemento com o ID 'root' quando a largura da tela 
   * está entre 769px e 965px. 
   * Define a largura como 100% para garantir que o conteúdo se ajuste ao tamanho da tela. 
*/
@media screen and (min-width: 769px) and (max-width: 965px){
  #root{
    width: 100%;
    min-height: 100vh;
  }
}
